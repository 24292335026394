<template>
  <div>
    <s-header :name="'意见反馈'"></s-header>
    <div class="tipImg" v-if="list.length==0">
      <img src="@/assets/tip.png"/>
      <p>您还没有反馈意见</p>
    </div>
    <div v-else>
      <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="(item,index) in list" :key="index">
          <van-cell is-link center>
            <template #title>
              <div style="display: flex;flex-direction: column">
                <div class="address-box">
                  <span class="tag">{{ filters(item.type) }}</span>
                  <span class="address in1line">{{ item.content }}</span>
                </div>
                <div class="u-box">
                  <span class="name">{{ item.reply || '管理员未回复' }}</span>
                  <span class="mobile">联系方式：{{ item.contact_way || '暂无' }}</span>
                </div>
              </div>
            </template>
          </van-cell>
          <div class="van-hairline--bottom"></div>
        </div>
      </van-list>
    </div>
    <div class="submitBtn">
      <van-button round block color="#8C211B" native-type="submit" @click="gotoIdea">意见反馈</van-button>
    </div>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import {useRouter} from 'vue-router'
import {onMounted, reactive, toRefs} from "vue";
import {getOpinion} from "../../../service/user";

export default {
  name: "Feedback",
  components: {
    sHeader
  },
  setup() {
    const router = useRouter()
    const state = reactive({
      page: 0,
      list: [],
      loading: false,
      finished: false
    })

    const onLoad = async () => {
      state.page++
      state.loading = true
      await getFeedbackList()
    };

    onMounted(async () => {
      await onLoad()
    })

    const getFeedbackList = async () => {
      const opinion = await getOpinion(state.page)
      state.loading = false
      state.list = state.list.concat(opinion.data);
      if (opinion.data.length === 0) {
        state.finished = true;
      }
    }

    function gotoIdea() {
      router.push({path: '/idea'})
    }

    function filters(type) {
      const feedbackType = ['', '功能建议', 'BUG反馈', '业务咨询']
      return feedbackType[parseInt(type, 10)]
    }

    return {
      ...toRefs(state),
      gotoIdea,
      getFeedbackList,
      filters,
      onLoad
    }
  }
}
</script>

<style scoped lang="less">
.address-box {
  display: flex;
  align-items: center;

  .tag {
    font-size: 12px;
    color: #fa436a;
    margin-right: 10px;
    background: #fffafb;
    border: 1px solid #ffb4c7;
    border-radius: 4px;
    padding: 4px 10px;
    line-height: 1;
    text-align: center;
    width: 50px;
  }

  .address {
    font-size: 15px;
    color: #fa436a;
  }
}

.u-box {
  font-size: 14px;
  color: #909399;
  margin-top: 16px;

  .name {
    margin-right: 30px;
  }
}

.tipImg {
  width: 40%;
  margin: 30px auto;

  img {
    width: 100%;
  }

  p {
    text-align: center;
    margin-top: 50px;
  }
}

.submitBtn {
  margin: 50px 20px;
}
</style>
